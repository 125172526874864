import { Action, Reducer } from 'redux';
import { get, set } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist';

import {
  BUILD_YOUR_COVER_INVALID_PRODUCT_TYPES,
  BUILD_YOUR_COVER_RESET_PRODUCT,
  BUILD_YOUR_COVER_SELECT_PRODUCT,
  BUILD_YOUR_COVER_SERVICES_CHANGE,
  BUILD_YOUR_COVER_STEP_CHANGE,
} from '../../actions/action-types';
import { BuildYourCoverProductType } from '../../actions/build-your-cover';
import { ProductType, ProductTypeType } from '../../types/product';
import { Service } from '../../types/__codegen__/ahm-api/graphql';

type SelectServices = Action & {
  productType: BuildYourCoverProductType;
  selectedServices: Service['serviceId'][];
};

type SelectProduct = Action & {
  productType: BuildYourCoverProductType;
  selectedProductId: ProductType['id'];
};

type SetInvalidProductTypes = Action & {
  invalidProductTypes: BuildYourCoverProductType[];
};

type ChangeStep = Action & {
  activeStep: string;
};

type Rehydrate = Action & {
  key: 'buildYourCover';
  payload: object;
};

export type ActionType = SelectServices | ChangeStep | SelectProduct | SetInvalidProductTypes | Rehydrate;

export type BuildYourCoverStateType = {
  activeStep: string | null;
  invalidProductTypes: ProductTypeType[];
  selectedProducts: {
    extras: ProductType['id'] | null;
    hospital: ProductType['id'] | null;
  };
  selectedServices: {
    extras: Service['serviceId'][];
    hospital: Service['serviceId'][];
  };
};

const initialState = {
  activeStep: null,
  invalidProductTypes: [],
  selectedProducts: {
    extras: null,
    hospital: null,
  },
  selectedServices: {
    extras: [],
    hospital: [],
  },
} as BuildYourCoverStateType;

const buildYourCoverReducer: Reducer<BuildYourCoverStateType, ActionType> = (
  // eslint-disable-next-line default-param-last -- this is a conflict between eslint and redux
  state = initialState,
  action,
): BuildYourCoverStateType => {
  const reducers = {
    [BUILD_YOUR_COVER_INVALID_PRODUCT_TYPES]: () => {
      const { invalidProductTypes } = action as SetInvalidProductTypes;
      return set('invalidProductTypes', invalidProductTypes, state);
    },

    [BUILD_YOUR_COVER_RESET_PRODUCT]: () => {
      const { productType } = action as SelectProduct;
      return set(['selectedProducts', productType], initialState.selectedProducts[productType], state);
    },

    [BUILD_YOUR_COVER_SELECT_PRODUCT]: () => {
      const { productType, selectedProductId } = action as SelectProduct;
      return set(['selectedProducts', productType], selectedProductId, state);
    },

    [BUILD_YOUR_COVER_SERVICES_CHANGE]: () => {
      const { productType, selectedServices } = action as SelectServices;
      return set(['selectedServices', productType], selectedServices, state);
    },

    [BUILD_YOUR_COVER_STEP_CHANGE]: () => ({
      ...state,
      activeStep: (action as ChangeStep).activeStep,
    }),

    [REHYDRATE]: () => {
      const { key, payload } = action as Rehydrate;
      if (key === 'buildYourCover') {
        return { ...state, ...payload };
      }

      return state;
    },
  };

  const reducer = get(action.type, reducers);
  return reducer ? reducer() : state;
};

export default buildYourCoverReducer;
